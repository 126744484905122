<template>
  <div>
    <div
      class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex flex-col relative"
    >
      <h2 class="text-lg uppercase">Service Tickets</h2>
      <div class="relative flex items-baseline my-4">
        <div
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex items-center justify-center"
          v-show="viewState == 'Loading'"
        >
          <Spinner :color="'text-black'" :size="10" />
        </div>
        <div
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex flex-col justify-center"
          v-if="viewState == 'Error'"
        >
          <span class="leading-none font-bold text-2xl text-red-600 mb-2"
            >Error</span
          >
          <span class="leading-none text-lg text-red-600"
            >{{ error.status }}: {{ error.statusText }}</span
          >
        </div>
        <span class="text-5xl">{{ newTicketCount || "--" }}</span>
        <span class="uppercase text-lg ml-2">New</span>
        <span class="text-5xl mx-3">/</span>
        <span class="text-5xl">{{ inReviewTicketCount || "--" }}</span>
        <span class="uppercase text-lg ml-2">Under Review</span>
      </div>
      <router-link
        class="absolute inset-0 hidden group-hover:block"
        to="/tickets"
      >
      </router-link>
      <div class="flex items-center justify-end text-lg">
        <button
          class="z-10 border border-secondary hover:bg-secondary hover:text-primary-highlight transition-colors duration-100 ease-in-out pl-2 pr-3 py-1 rounded flex items-center"
          @click="showingCreateTicketPanel = true"
        >
          <svg
            class="stroke-current h-6 w-6 mr-2"
            fill="none"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path
              d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
            />
          </svg>
          <span>Create Ticket</span>
        </button>
      </div>
    </div>

    <portal to="overlay-outlet">
      <Panel
        :showing="showingCreateTicketPanel"
        @close="showingCreateTicketPanel = false"
        title="Create Service Ticket"
      >
        <CreateServiceTicket @complete="serviceTicketCreated" />
      </Panel>
    </portal>
  </div>
</template>

<script>
const CreateServiceTicket = () =>
  import("@/components/Dashboard/ServiceTickets/CreateServiceTicket.vue");
const Panel = () => import("@/components/Panel.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");

export default {
  name: "ServiceTicketWidget",
  components: {
    Panel,
    CreateServiceTicket,
    Spinner,
  },
  data() {
    return {
      viewState: "Idle",
      newTicketCount: 0,
      inReviewTicketCount: 0,
      showingCreateTicketPanel: false,
      error: null,
    };
  },
  async mounted() {
    await this.loadTickets();
  },
  methods: {
    serviceTicketCreated: async function() {
      this.showingCreateTicketPanel = false;
      await this.loadTickets();
    },
    loadTickets: async function() {
      this.viewState = "Loading";
      try {
        let codeResult = await this.ServiceTicketService.getServiceTickets();

        this.newTicketCount = codeResult.data.filter((t) => t.is_new).length;
        this.inReviewTicketCount = codeResult.data.filter(
          (t) => !t.is_new && !t.is_closed
        ).length;
      } catch (error) {
        console.error("ReturnCodeWidget error", error);
        this.error = error;
      } finally {
        this.viewState = "Idle";
      }
    },
  },
};
</script>

<style  scoped>
.card-link {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.enquiries-container {
  flex-direction: row;
}
@media screen and (min-width: 768px) and (max-width: 1225px) {
  .enquiries-container {
    flex-direction: column;
  }
}
</style>
